<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="container">

        <b-row>

            <b-breadcrumb>
                <b-breadcrumb-item :to="{ name: 'intentoDashboard' }">
                    <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                    Dashboard
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'user' }">
                    My Profile
                </b-breadcrumb-item>
            </b-breadcrumb>

        </b-row>

        <b-row>

            <b-col>

                <b-card class="user-profile">

                    <b-row>

                        <b-col cols="8">

                            <h3>
                                {{model.name}}
                            </h3>

                            <strong>{{model.userName}}</strong>
                            <p v-if="model.phone">
                                <strong>Phone:</strong>
                                {{model.phone}}
                            </p>

                        </b-col>

                        <b-col cols="4">
                            <b-list-group class="user-roles">
                                <b-list-group-item v-for="(role,index) in model.roles" :key="index" class="user-role">{{role}}</b-list-group-item>
                            </b-list-group>
                        </b-col>

                    </b-row>

                </b-card>

            </b-col>

        </b-row>

    </div>
</template>

<script>
    import ApiService from '../services/api.service';

    export default {
        name: 'UserProfile',

        props: {
            userId: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                model: ''
            };
        },
        mounted() {
            ApiService.getUser(this.userId).then(
                response => {
                    this.model = response.data;
                    if (!response.data.name) {
                        this.model.name = response.data.firstName + " " + response.data.lastName
                    }
                },
                error => {
                    this.model =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    };
</script>

<style scoped>

    .user-profile {
        padding: 20px;
    }

    .user-roles {
        font-size: 60%;
    }
</style>